import Login from '@/views/Login'
import MyPage from '@/views/MyPage'
import Work from '@/views/Work'
import ItemList from '@/views/ItemList'


export default [
    {
        path: '/',
        name: 'ItemList',
        component: ItemList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/mypage',
        name: 'MyPage',
        component: MyPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/work',
        name: 'Work',
        component: Work,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/itemList',
        name: 'ItemList',
        component: ItemList,
        meta: {
            requiresAuth: true
        }
    },
]
