<template>
  <v-app>
    <div class="asin-interlocked">
      <h2>Amazon出品情報入力</h2>
      <v-form ref="form" id="inspire">
        <v-flex>
          <input type="radio" id="normal" v-model="inventoryType" value="1"/>
          <label for="normal">通常在庫</label>
          <br/>
          <input type="radio" id="bad" v-model="inventoryType" value="2"/>
          <label for="bad">項目選択肢別在庫（バリエーションあり）</label>
          <v-layout row>
            <v-col cols="6" sm="3">
              <v-text-field
                  v-model="priceRatio"
                  label="利益幅 入力例:1.5"
                  type="number"
                  step="0.1"
                  :rules="[rules.required, rules.priceRate]"
                  required
              ></v-text-field>
            </v-col>
          </v-layout>
          <div v-if="is_multiple">
            <v-layout row>
              <v-col cols="6" sm="3">
                <v-text-field
                    v-model="horizontalName"
                    label="横軸項目名 入力例:カラー"
                    maxlength="200"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                    v-model="verticalName"
                    label="縦軸項目名 入力例:サイズ"
                    maxlength="200"
                ></v-text-field>
              </v-col>
            </v-layout>
            <v-btn @click="addInput()">追加</v-btn>
            <v-layout row v-for="input in multipleInputList" :key="input.id">
              <v-col cols="6" sm="2">
                <v-text-field
                    v-model="input.asinCode"
                    label="ASINコード"
                    maxlength="10"
                    :rules="[rules.required, rules.asinCode]"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                    v-model="input.optionNameHorizontal"
                    label="横軸名 入力例:赤"
                    maxlength="200"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" v-if="use_vertical">
                <v-text-field
                    v-model="input.optionNameVertical"
                    label="縦軸名 入力例:S"
                    maxlength="200"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-col>
              <v-btn fab dark small color="red" @click="removeInput(input.id)"
              >削除
              </v-btn
              >
            </v-layout>
          </div>
          <v-layout row>
            <v-col cols="12" sm="6">
              <v-textarea
                  v-model="asinCodeInput"
                  label="ASINコード（複数行貼り付け可）"
                  hint="CSVやExcelからASINコードを貼り付けてください"
                  rows="5"
              ></v-textarea>
              <v-btn @click="processBulkInput()">登録</v-btn>
            </v-col>
          </v-layout>
        </v-flex>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-form>
    </div>
    <snackbar></snackbar>
  </v-app>
</template>

<script>
import axios from "axios";
import Snackbar from "@/components/snackbar";
import {mapActions} from "vuex";

export default {
  name: "Asin-interlocked",
  components: {Snackbar},
  computed: {
    is_multiple() {
      return this.inventoryType == "2";
    },
    use_vertical() {
      return this.is_multiple && this.verticalName != "";
    },
  },
  data() {
    return {
      inventoryType: "1",
      multipleInputList: [
        {
          id: 0,
          asinCode: "",
          optionNameHorizontal: "",
          optionNameVertical: "",
        },
      ],
      priceRatio: 1.5,
      overlay: false,
      horizontalName: "",
      verticalName: "",
      asinCodeInput: "",
      rules: {
        required: (value) => !!value || "必須項目です",
        priceRate: (value) => {
          return 1.0 < value || "利益幅は1.1以上の数値で入力してください";
        },
        asinCode: (value) => {
          return 10 == value.length || "ASINコードは10桁で入力してください";
        },
      },
    };
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    addInput() {
      let multipleInputList = this.multipleInputList;
      multipleInputList.push({
        id: multipleInputList.length,
        asinCode: "",
        optionNameHorizontal: "",
        optionNameVertical: "",
      });
    },
    removeInput(id) {
      let multipleInputList = this.multipleInputList;
      multipleInputList = multipleInputList.filter((input) => {
        return input.id !== id;
      });
      this.multipleInputList = multipleInputList.map((input, index) => ({
        ...input,
        id: index,
      }));
    },
    async processBulkInput() {
      // テキストエリアの入力を1行ずつ分割して配列に格納
      const asinCodes = this.asinCodeInput.split(/\r?\n/).filter((code) => code.trim() !== "");

      this.overlay = true;
      for (let i = 0; i < asinCodes.length; i++) {
        try {
          const singleParam = {
            inventoryType: this.inventoryType,
            priceRatio: this.priceRatio,
            childItems: [
              {
                asinCode: asinCodes[i],
              },
            ],
          };
          await axios.post("/items", singleParam, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          this.openSnackbar(`登録成功: ${asinCodes[i]}`);
        } catch (e) {
          this.openSnackbar(`登録失敗: ${asinCodes[i]}`);
        }
      }
      // 登録後にテキストエリアをクリア
      this.asinCodeInput = "";
      this.overlay = false;
      this.openSnackbar("すべての登録が完了しました。");
    },
  },
};
</script>
