import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import axios from 'axios';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
// 本番環境用
axios.defaults.baseURL = "https://api.dayseeds.com/";

// docker-compose.yml用
// axios.defaults.baseURL = "http://localhost:8888/";

// IDE用
// axios.defaults.baseURL = "http://localhost:8000/";
Vue.use({
    install(Vue) {
        Vue.prototype.$axios = axios.create({});
    }
});

new Vue({
    vuetify,
    el: '#app',
    router,
    store,
    render: h => h(App)
}).$mount('#app');
