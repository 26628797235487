<template>
  <v-app>
    <v-card width="400px" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">ログイン</h1>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
              prepend-icon="mdi-account-circle"
              label="ユーザーID"
              v-model="authId"
              :rules="[rules.required]"
              autocomplete="username"
          />
          <v-text-field
              v-bind:type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              prepend-icon="mdi-lock"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="パスワード"
              v-model="password"
              :rules="[rules.required]"
              autocomplete="current-password"
          />
        </v-form>
        <v-btn class="info" @click="submit">ログイン</v-btn>
      </v-card-text>
    </v-card>
    <snackbar>
    </snackbar>
  </v-app>
</template>

<script lang="ts">
import axios from "axios";
import Snackbar from "../components/snackbar.vue";
import {mapActions} from "vuex";

export default {
  name: "Login",
  components: {Snackbar},
  data() {
    return {
      showPassword: false,
      authId: "",
      password: "",
      rules: {
        required: (value) => !!value || "必須項目です",
      },
    };
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    async submit() {
      const result = this.$refs.form.validate();
      if (result == false) {
        localStorage.removeItem("token");
        return;
      }
      const params = new URLSearchParams();
      params.append("username", this.authId);
      params.append("password", this.password);
      try {
        await axios.post("/auth/login", params).then((response) => {
          // ローカルストレージにAPI認証用トークンを格納
          localStorage.setItem("token", response.data.access_token);
          this.$router.push("/work");
          location.reload();
        });
      } catch (e) {
        if (e.response.data.detail.error_msg == undefined) {
          this.openSnackbar(
              "ログインに失敗しました。システム管理者にお問い合わせください。"
          );
          return;
        }
        this.openSnackbar(e.response.data.detail.error_msg);
      }
    },
  },
};
</script>
