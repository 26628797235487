<template>
  <v-snackbar v-model="$store.state.snackbar" timeout="2000">
    {{ $store.state.snackbarText }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="closeSnackbar">
        close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>

import {mapActions} from "vuex";

export default {
  name: "snackbar",
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
  },
};
</script>
