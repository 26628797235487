<template>
  <v-app>
    <v-card width="400px" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">ライセンスキー変更</h1>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field
              v-bind:type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              prepend-icon="mdi-lock"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="RMSAPIのライセンスキー"
              v-model="licenseKey"
              maxlength="26"
              :rules="[rules.required]"
          />
        </v-form>
        <v-btn class="info" @click="userInfoUpdate">更新</v-btn>
      </v-card-text>
    </v-card>
    <snackbar>
    </snackbar>
  </v-app>
</template>

<script>
import Snackbar from "@/components/snackbar";
import {mapActions} from "vuex";

export default {
  name: "MyPage",
  components: {Snackbar},
  data() {
    return {
      licenseKey: "",
      showPassword: true,
      rules: {
        required: (value) => !!value || "必須項目です",
      },
    };
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    // ユーザー情報更新API実行
    async userInfoUpdate() {
      const result = this.$refs.form.validate();
      if (result == false) {
        localStorage.removeItem("token");
        return;
      }
      const data = {
        pre_encrypt_license_key: this.licenseKey,
      };
      await this.$axios
          .patch("/users/me", data, {
            headers: {Authorization: "Bearer " + localStorage.getItem("token")},
          })
          .then(() => {
            this.openSnackbar("ライセンスキーを更新しました。");
          })
          .catch((error) => {
            if (error.response.status == 403) {
              this.openSnackbar("認証エラーが発生しました。ログインしなおしてください。");
              // 認証失敗時に再ログインさせる
              localStorage.removeItem("token");
              location.reload();
              return;
            }
            this.openSnackbar("ライセンスキーの更新に失敗しました。再度やり直してください。");
          });
    },
  },
};
</script>
