<template>
  <v-app>
    <header>
      <v-app-bar app dark>
        <v-toolbar-title></v-toolbar-title>
        <v-tabs v-if="is_login">
          <!-- 未ログインの場合 -->
          <v-tab id="login-nav" to="/" exact>ログイン</v-tab>
          <v-tabs-slider/>
        </v-tabs>
        <v-tabs v-else>
          <!-- ログインできていたら表示 -->
          <v-tab id="item-list-nav" to="/itemList">出品済み商品一覧画面</v-tab>
          <v-tab id="work-nav" to="/work">Amazon出品情報入力</v-tab>
          <v-tab id="mypage-nav" to="/mypage">ラインセンスキー変更</v-tab>

          <v-tabs-slider/>
        </v-tabs>
      </v-app-bar>
    </header>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    is_login() {
      return localStorage.getItem("token") == null;
    },
  },
  data: () => ({}),
};
</script>
