import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        isLogin: false,
        userId: '',
        snackbar: false,
        snackbarText: "",
    },
    mutations: {
        auth(state, user) {
            state.isLogin = true;
            state.userId = user;
        },
        setSnackbar: (state, snackbarText) => {
            state.snackbar = true;
            state.snackbarText = snackbarText;
        },
        unsetSnackbar: (state) => {
            state.snackbar = false;
            state.snackbarText = "";
        },
    },
    actions: {
        fetch(context, user) {
            context.commit('auth', user);
        },
        openSnackbar: ({commit}, snackbarText) => {
            commit("setSnackbar", snackbarText);
        },
        closeSnackbar: ({commit}) => {
            commit("unsetSnackbar");
        },
    }
})
export default store
