<template>
  <div class="asin-interlocked">
    <h2>出品済み商品一覧画面</h2>
    <v-app id="inspire">
      <div v-if="this.itemList != []">
        <template>
          <v-text-field
              v-model="search"
              label="検索"
              class="mx-4"
          ></v-text-field>
        </template>
        <v-data-table
            :headers="headers"
            :items="itemList"
            item-key="manager_number"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
            items-per-page=50
            :loading="loading"
            dense
            multi-sort
            :footer-props="{
              itemsPerPageOptions:[50,100,300,500,1000]
            }"
        >
          <template #[`item.manager_number`]="{ item }">
            <v-text-field v-text="item.manager_number"/>
          </template>
          <template #[`item.rms_title`]="{ item }">
            <v-text-field
                v-text="item.rms_title"
                v-model="item.rms_title"
            />
          </template>
          <template #[`item.before_margin_price`]="{ item }">
            <v-text-field v-text="'¥' +item.before_margin_price"/>
          </template>
          <template #[`item.price_ratio`]="{ item }">
            <v-text-field
                v-model="item.price_ratio"
                label="利益幅 1.1以上の数値"
                type="number"
                step="0.01"
                :rules="[rules.required, rules.priceRate]"
                required
            />
          </template>
          <template #[`item.col5`]="{ item }">
            <v-text-field v-text="'¥' + rakutenResultPrice(item)"/>
          </template>
          <template #[`item.col6`]="{ item }">
            <v-btn @click="updatePriceRate(item)">利益幅更新</v-btn>
          </template>
          <template #[`item.delete`]="{ item }">
            <v-btn @click="deleteItem(item)">削除</v-btn>
          </template>
        </v-data-table>
      </div>
      <snackbar>
      </snackbar>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import {mapActions} from "vuex";
import Snackbar from "@/components/snackbar";

export default {
  name: "itemList",
  components: {Snackbar},
  data() {
    return {
      itemList: [],
      search: "",
      loading: true,
      overlay: false,
      rules: {
        required: (value) => !!value || "必須項目です",
        priceRate: (value) => {
          return 1.0 < value || "利益幅は1.1以上の数値で入力してください";
        },
      },
    };
  },
  /* eslint-disable */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getItemList();
    });
  },
  /* eslint-disable */
  beforeRouteUpdate(to, from, next) {
    next();
  },
  computed: {
    headers() {
      return [
        {
          text: "楽天出品ID",
          value: "manager_number",
          align: "start",
        },
        {
          text: "商品名",
          value: "rms_title",
          align: "start",
        },
        {
          text: "前回同期時のAmazon価格",
          value: "before_margin_price",
          align: "start",
        },
        {
          text: "利益幅",
          value: "price_ratio",
          align: "start"
        },
        {
          text: "利益幅更新後価格",
          value: "col5",
          sortable: false,
          align: "start",
        },
        {
          text: "",
          value: "col6",
          sortable: false,
          align: "start",
        },
        {
          text: "",
          value: "delete",
          sortable: false,
          align: "start",
        },
      ];
    },
  },
  methods: {
    ...mapActions(["openSnackbar", "closeSnackbar"]),
    rakutenResultPrice: function (item) {
      return `${parseInt(item.price_ratio * item.before_margin_price)}`;
    },
    filterOnlyCapsText(value, search, item) {
      return (
          value != null &&
          search != null &&
          typeof value === "string" &&
          value.toString().indexOf(search) !== -1
      );
    },
    // 画面表示時の商品リスト取得処理
    async getItemList() {
      this.overlay = true;
      try {
        await axios
            .get("/items", {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              this.overlay = false;
              this.itemList = res.data;
              this.loading = false;
            });
      } catch (e) {
        if (e.response.status == 403) {
          this.openSnackbar("認証エラーが発生しました。ログインしなおしてください。");
          this.overlay = false;
          // 認証失敗時に再ログインさせる
          localStorage.removeItem("token");
          location.reload();
          return;
        }
        this.openSnackbar(e);
        this.overlay = false;
        this.openSnackbar("商品一覧取得に失敗しました");
      }
    },
    // 商品の利益幅変更
    async updatePriceRate(item) {
      this.overlay = true;
      const requestParams = {
        managerNumber: item.manager_number,
        price_ratio: item.price_ratio,
      };
      try {
        await axios
            .post("/items/price", requestParams, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then(() => {
              this.overlay = false;
              this.openSnackbar("利益幅の更新が完了しました")
            });
      } catch (e) {
        this.overlay = false;
        this.openSnackbar("利益幅の更新に失敗しました")
      }
    },
    // 商品の削除
    async deleteItem(item) {
      this.overlay = true;
      const deletePromises = item.child_items.map(child => {
        return axios.post("/items/delete", {asinCode: child.asin_code}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
      });

      try {
        await Promise.all(deletePromises);
        this.overlay = false;
        this.openSnackbar("商品が削除されました");
        await this.getItemList(); // 更新された商品リストを再取得
      } catch (e) {
        this.overlay = false;
        this.openSnackbar("商品の削除に失敗しました");
      }
    },
  },
};
</script>

<style>
[v-cloak] {
  /* display: none; */
  background-color: brown;
}

.aaaa {
  background-color: brown;
}
</style>
